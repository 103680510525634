import { ETextStyleVariant, Icon, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { roundRatingNumber } from '@/utility/reviews';

type TRentalRatingProps = {
  rating: number;
  quantity?: number;
  textVariant?: ETextStyleVariant;
};

export const RentalRating = ({
  rating,
  quantity,
  textVariant = ETextStyleVariant.MediumRegular,
}: TRentalRatingProps) => {
  const intl = useIntl();
  // enforce single decimal digit and hide decimal if a whole number
  const fixedRating = roundRatingNumber(rating);

  return (
    <Text
      component="span"
      variant={textVariant}
      aria-label={intl.formatMessage(
        { defaultMessage: '{rating} out of 5 stars', id: 'Wp2bK6' },
        { rating: fixedRating },
      )}
      className="flex items-center whitespace-nowrap">
      <Icon name="General.Star.Fill" className="mr-1 text-green-500" />
      {fixedRating}
      {quantity && <> ({quantity})</>}
    </Text>
  );
};
