import React from 'react';
import { useIntl } from 'react-intl';

import { formatCurrency } from '@/utility/currency';

import { PriceSize } from '../Price';
import css from './PriceOriginal.module.css';

type IPriceOriginalElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  currency?: string;
  originalPrice: number;
  size?: PriceSize;
  testId?: string;
}

const PriceOriginal: React.FC<IProps & IPriceOriginalElement> = ({
  size = PriceSize.medium,
  currency,
  originalPrice,
  testId = 'price-original',
}) => {
  const intl = useIntl();

  const formattedPrice = formatCurrency({
    currency,
    ceil: true,
    digits: 0,
    maxDigits: 0,
    priceInCents: originalPrice,
  });

  const screenReaderMessage = intl.formatMessage(
    {
      description: "[Price]: listing's original price, before discount",
      defaultMessage: 'Original price was {value}',
      id: '/HrfDe',
    },
    {
      value: formattedPrice,
    },
  );

  return (
    <p
      aria-label={screenReaderMessage}
      className={css.wrapper}
      data-testid={testId}
      data-size={size}>
      <span aria-hidden>{formattedPrice}</span>
    </p>
  );
};

export default PriceOriginal;
