import { ETextStyleVariant, Icon, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { defaultLocale, TCurrency } from '@/config/locales';
import { formatCurrency } from '@/utility/currency';
import { IPrice } from '@/utility/mapSearchResultToTile';

type TRentalPriceProps = IPrice & {
  textClassName?: string;
  currency?: TCurrency;
  showOriginalPrice?: boolean;
  isCampsite?: boolean;
  instantBook?: boolean;
};

export const RentalPrice = ({
  isCampsite = true,
  currency = defaultLocale.base_currency,
  originalPrice,
  currentPrice,
  showOriginalPrice = isCampsite ? false : currentPrice < originalPrice,
  period,
  textClassName,
  instantBook,
}: TRentalPriceProps) => {
  const intl = useIntl();

  const hasDiscount = currentPrice < originalPrice;

  const formattedCurrentPrice = formatCurrency({
    currency,
    digits: 0,
    maxDigits: 0,
    priceInCents: currentPrice,
  });

  const formattedOriginalPrice = formatCurrency({
    currency,
    ceil: true,
    digits: 0,
    maxDigits: 0,
    priceInCents: originalPrice,
  });

  return (
    <div className="flex items-center">
      {instantBook && (
        <Icon
          name="General.InstantBook"
          aria-label={intl.formatMessage({
            description: '[Price]: screen reader message for instant book',
            defaultMessage: 'This listing allows instant booking.',
            id: 'ZpLmIK',
          })}
          className="mr-1 text-canvas-300"
        />
      )}

      <Text
        component="span"
        variant={ETextStyleVariant.LargeBold}
        aria-label={intl.formatMessage(
          {
            description: '[Price]: screen reader message for current price per period (day/night)',
            defaultMessage: '{currentPrice} per {period}',
            id: 'RgOVlk',
          },
          {
            currentPrice: formattedCurrentPrice,
            period,
          },
        )}
        className={`contents ${textClassName}`}>
        {isCampsite
          ? intl.formatMessage(
              {
                description: '[Price]: current price formatting for a listing',
                defaultMessage:
                  '<priceTemplate>from {value}</priceTemplate><periodTemplate>/{period, select, day {day} night {night} other {}}</periodTemplate>',
                id: 'jknpg+',
              },
              {
                priceTemplate: currentPriceTemplate,
                periodTemplate: periodTemplate,
                period: period.toLowerCase(),
                value: formattedCurrentPrice,
              },
            )
          : intl.formatMessage(
              {
                description: '[Price]: current price formatting for a listing',
                defaultMessage:
                  '<priceTemplate>{value}</priceTemplate><periodTemplate>/{period, select, day {day} night {night} other {}}</periodTemplate>',
                id: 'eJ7KW9',
              },
              {
                priceTemplate: currentPriceTemplate,
                periodTemplate: periodTemplate,
                period: period.toLowerCase(),
                value: formattedCurrentPrice,
              },
            )}
      </Text>

      {hasDiscount && showOriginalPrice && (
        <Text
          component="span"
          variant={ETextStyleVariant.LargeBold}
          aria-label={intl.formatMessage(
            {
              description: "[Price]: listing's original price, before discount",
              defaultMessage: 'Original price was {value}',
              id: '/HrfDe',
            },
            {
              value: formattedOriginalPrice,
            },
          )}
          className={`order-3 ml-1 text-gray-500 line-through ${textClassName}`}>
          {formattedOriginalPrice}
        </Text>
      )}
    </div>
  );
};

const currentPriceTemplate = (value: React.ReactNode) => (
  <span aria-hidden className="order-2">
    {value}
  </span>
);

const periodTemplate = (period: React.ReactNode) => (
  <span aria-hidden className="order-4 ml-1">
    {period}
  </span>
);
